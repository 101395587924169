import CORE from "./js/core.js"
 


 
window.onload = function () {
    CORE.onload();
    
};

/*   window.addEventListener('DOMContentLoaded', (event) => {
    console.log('Ya esta catgado aun que las imagenes no');
}); */